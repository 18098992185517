import React, { useEffect } from 'react';
import commonConfig from '@packages/fdpq-core/config/common';

const listenToEvent = () => {
  if (typeof window !== 'undefined') {
    window.addEventListener('message', (event) => {
      if (event.data.type !== 'onFdpqWidgetHeightChanged') {
        console.info('event', event); // eslint-disable-line no-console
      }
    });
  }
};

const iframetest = () => {
  useEffect(() => listenToEvent(), []);
  return (
    <div>
      <h1>Parent Page With iFrame</h1>
      <iframe scrolling="no"
        src={`${commonConfig.fdpqCdnBaseUrl}/${commonConfig.branch}/?apikey=00e8fa39-3235-46a5-b23e-6dae5728bcbc&atcmakename=ford&atcmodelcode=flex&atctrimname=sel&pageName=atchost&origin=*`}
        style={{
          borderWidth: '2px',
          borderStyle: 'inset',
          width: '100%',
          height: '440px',
          transitionProperty: 'height',
          transitionDuration: '0.25s',
          transitionTimingFunction: 'ease',
        }}
      >
      </iframe>
    </div>
  );
};

export default iframetest;
